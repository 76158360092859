// extracted by mini-css-extract-plugin
export var addFile = "index-module--addFile--xBNVV";
export var anotherIntro = "index-module--anotherIntro--708Tp";
export var buttonStyle = "index-module--buttonStyle--pEAA1";
export var copyright = "index-module--copyright--ZeHXe";
export var formBanner = "index-module--formBanner--Yj1Eu";
export var formContainer = "index-module--formContainer--hi8Jm";
export var greatfulMessage = "index-module--greatfulMessage--ieMnQ";
export var imgContainer = "index-module--imgContainer--oSMFv";
export var modal = "index-module--modal--+HRTf";
export var modalFooter = "index-module--modalFooter--1+O4d";
export var submitSuccess = "index-module--submitSuccess--iogDw";
export var upload = "index-module--upload--p+7eo";