import React, { useState, useRef, useEffect } from "react";
import { useSessionStorageState } from "ahooks";

import * as styles from "./index.module.scss";
import { Link } from "gatsby";
import addIcon from "@src/images/complaints/addIcon.png";

// import emailjs from "emailjs-com";

import { copyrightRequest, uploadFileRequest } from "@src/utils/formrequest";
import {
  Input,
  Form,
  Radio,
  Space,
  Upload,
  Checkbox,
  Button,
  Col,
  Row,
  Modal,
  message as showMessage
} from "antd";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Header from "@components/header";

import Footer from "@components/footer";

const CopyRight = () => {
  const data = useStaticQuery(graphql`
    query {
      formBanner: file(relativePath: { eq: "complaints/formBanner.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallFormBanner: file(
        relativePath: { eq: "complaints/smallFormBanner.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      noticeImg: file(relativePath: { eq: "complaints/noticeImg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallNoticeImg: file(
        relativePath: { eq: "complaints/smallNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      repeatNoticeImg: file(
        relativePath: { eq: "complaints/repeatNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  //一些额外需要存储的数据
  // const [role, setRole] = useState("我是版权的拥有者");
  const [uploadFile, setUploadFile] = useState([]);
  const [certificate, setCertificate] = useState([]);



  //modal相关的操6作
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRepeatModalVisible, setRepeatModalVisible] = useState(false);

  //表单提交的时候清空本地缓存
  const onFinish = (value) => {
    console.log(value, certificate, uploadFile, "this is log");

    const {
      name,
      email,
      phone_number,
      copyright_url,
      infringement_target,
      infringement_url,
      added,
      role
    } = value;
    copyrightRequest({
      certificate,
      file: uploadFile,
      role,
      name,
      email,
      phone: phone_number,
      copyright_url,
      shop_url: infringement_target,
      commodity_url: infringement_url,
      remark: added,
    }).then(
      (res) => {
        //发送邮件成功后的回调
        // console.log("我发送信息成功了，copyright", res);

        const { data: { resultCode, message } } = res;
        if (resultCode === 200) {
          showModal();
          setFields(null);
        }
        else if (resultCode === 205) {
          setFields(null)
          setRepeatModalVisible(true)
        }
        else {
          showMessage.error(message)
          // console.log('发生了其他意料之外的错误');
        }
      },
      (err) => {
        showMessage.error("提交表单失败,", err)
        // console.log("我发送信息失败了，copyright", err);
      }
    );
  };
  //上传文件的相关逻辑
  const customRequest = ({ file, onSuccess, onError }) => {
    if (file.size / 1024 / 1024 < 10) {
      //文件小于10M才上传
      uploadFileRequest(file).then(
        (res) => {
          onSuccess(res, file);
          console.log("这是上传文件之后拿到的数据");
          setUploadFile([...uploadFile, res.data.data.path]);
        },
        (err) => {
          onError(err, file);
        }
      );
    }

  };
  //上传证书的相关逻辑
  const uoloadCertificateRequest = ({ file, onSuccess, onError }) => {
    if (file.size / 1024 / 1024 < 10) {
      //文件小于10M 才能上传
      uploadFileRequest(file).then(
        (res) => {
          onSuccess(res, file);
          console.log("这是上传文件之后拿到的数据");
          setCertificate([...certificate, res.data.data.path]);
        },
        (err) => {
          onError(err, file);
        }
      );
    }

  };


  const normFile = (e) => {
    console.log("Upload event:", e);
    const { file, fileList } = e;
    if (file.size / 1024 / 1024 > 10) {
      //如果大小大于10M
      fileList.pop()
      showMessage.error("上传的文件不能超过10M")
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //数据回显功能使用ahooks 实现
  const [fields, setFields] = useSessionStorageState();

  useEffect(() => {
    //在退出这个页面的时候清空sessionStorage里面存的值
    return () => {
      // console.log("执行了清理操作");
      setFields(undefined);
    };
  }, []);

  const onFieldsChange = (_, allFields) => {
    setFields(allFields);
    // console.log(fields);
  };
  const copyright = useRef();
  const form = useRef()
  return (
    <div className={styles.copyright} ref={copyright}>
      <Header />

      {/* 表单 */}
      <div className={styles.formBanner}>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <Img fluid={data.formBanner.childImageSharp.fluid} />
            <h1>商家版权投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效，
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <Img fluid={data.smallFormBanner.childImageSharp.fluid} />
            <h1>商家版权投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效，
              <br />
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
        </Row>
      </div>
      <div className={styles.formContainer}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          fields={fields}
          initialValues={{
            role: "我是版权的拥有者"
          }}
          ref={form}
        >
          {/* 用户身份 */}
          <Form.Item label="请选择投诉角色" name='role'>
            <Radio.Group >
              <Space direction="vertical">
                <Radio value="我是版权的拥有者">我是版权的拥有者</Radio>
                <Radio value="我是版权所有者的授权代表">
                  我是版权所有者的授权代表
                </Radio>
                {/* <Radio value="其他">其他</Radio> */}
              </Space>
            </Radio.Group>
          </Form.Item>
          {/* 用户名字 */}
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: "请输入投诉人姓名" }]}
          >
            <Input placeholder="请填写投诉人姓名" />
          </Form.Item>
          {/* 用户邮箱 */}
          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { required: true, message: "请输入投诉联系人邮箱" },
              { type: "email", message: "请输入正确形式的邮箱" },
            ]}
          >
            <Input placeholder="请填写投诉人联系邮箱，用于后续回复" />
          </Form.Item>
          {/* 用户电话 */}
          <Form.Item
            label="电话"
            name="phone_number"
            rules={[
              { required: true, message: "请输入投诉人联系电话" },
              {
                pattern:
                  /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input placeholder="请填写投诉人联系电话，用于后续回复" />
          </Form.Item>
          {/* 版权证书/授权证书 */}

          <Form.Item
            label="版权证书/授权证书"
            name="certificate"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={styles.upload}
            extra={
              <div className={styles.anotherIntro}>
                请上传版权证书或者授权证书，如有多个可以直接上传压缩包
                (文件大小不能大于10M)
              </div>
            }
            rules={[
              {
                required: true,
                message: "请上传其他材料",
              },
            ]}
          >
            <Upload.Dragger
              name="files"
              customRequest={uoloadCertificateRequest}
            >
              {/* <span className={styles.addFile}>+</span> */}
              <img src={addIcon} alt="addImg" />
            </Upload.Dragger>
          </Form.Item>
          {/* 版权作品/商品的链接 */}
          <Form.Item
            label="版权作品/商品的链接"
            name="copyright_url"
            rules={[
              {
                required: true,
                message: "请输入版权作品/商品的链接",
              },
            ]}
          >
            <Input.TextArea placeholder="请填写版权作品/商品的链接，可填写多个，每个地址换行" />
          </Form.Item>

          {/* 侵权的店铺地址 */}
          <Form.Item
            label="侵权的店铺地址"
            name="infringement_target"
            rules={[
              {
                required: true,
                message: "请输入侵权的店铺地址",
              },
              {
                type: "url",
                message: "请输入正确的侵权店铺的url地址",
              },
            ]}
          >
            <Input placeholder="请填写侵权的店铺地址" />
          </Form.Item>

          {/* 侵权作品/商品的链接 */}
          <Form.Item
            label="侵权作品/商品的链接"
            name="infringement_url"
            rules={[
              {
                required: true,
                message: "请输入侵权作品/商品的链接",
              },
            ]}
          >
            <Input.TextArea placeholder="请填写侵权作品/商品的链接，可填写多个，每个地址换行" />
          </Form.Item>

          <Form.Item
            label="其他材料补充"
            name="another-material"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={styles.upload}
            extra={
              <div className={styles.anotherIntro}>
                上传能证明此网站侵权的材料证据（比如：图片，视频等，文件必须少于10MB）
              </div>
            }
            rules={[
              {
                required: false,
                message: "请上传其他材料",
              },
            ]}
          >
            <Upload.Dragger name="another_files" customRequest={customRequest}>
              <img src={addIcon} alt="addImg" />
              {/* <span className={styles.addFile}>+</span> */}
            </Upload.Dragger>
          </Form.Item>
          {/* 补充说明 */}
          <Form.Item
            label="补充说明"
            name="added"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea placeholder="可在此补充说明这些证据是如何证明该网站违规" />
          </Form.Item>
          {/* 声明 */}
          <Form.Item
            label="声明"
            name="allchecked"
            rules={[
              {
                validator(_, value = []) {
                  if (value.length === 3) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("请勾选全部"));
                },
              },
            ]}
          >
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox value="a">
                  本人本着诚信原则，此投诉内容未经版权所有者或其法定授权代理者授权。
                </Checkbox>

                <Checkbox value="b">
                  本人在伪证罪下宣誓，此投诉中的信息准确无误，并且本人是版权所有者或被法定授权代理者。
                </Checkbox>

                <Checkbox value="c">
                  本人确认，可以将此投诉的副本（包括上面提供的任何联系信息）提供给被投诉人。
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          {/* 提交按钮 */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "60px",
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "8px",
                marginTop: "40px",
              }}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}

        {/* 提交成功的modal */}
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          getContainer={copyright.current}
          closable={false}
          centered
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.noticeImg.childImageSharp.fluid} />
          </div>
          <div className={styles.submitSuccess}>提交成功</div>
          <div className={styles.greatfulMessage}>
            感谢您的反馈，我们会在审核资料后给您回复，请耐心等待。
          </div>
        </Modal>

        {/* 重复提交的modal */}
        <Modal
          visible={isRepeatModalVisible}
          getContainer={copyright.current}
          closable={false}
          maskClosable={false}
          centered
          onCancel={() => {
            setRepeatModalVisible(false)
          }}
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setRepeatModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.repeatNoticeImg.childImageSharp.fluid} />
          </div>
          {/* <div className={styles.submitSuccess}>您已经提交成功，无须再次提交</div> */}
          <div
            className={styles.greatfulMessage}
            style={{
              lineHeight: "80px",
            }}
          >
            您已经提交成功，无须再次提交
          </div>
        </Modal>

      </div>
      <Footer />
    </div>
  );
};

export default CopyRight;
